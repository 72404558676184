import type { LocaleString } from '@dreamstack/i18n'
import {
  useFormatToCurrencyCutoff,
  useFormatToSoftDecimal,
  useLocale,
} from '@dreamstack/i18n'
import type { FullBlockRibbonFragment } from '@dreamstack/investors-graphql'
import { useOnScreen } from '@dreamstack/util'
import { motion, useAnimation } from 'framer-motion'
import filter from 'lodash/filter'
import first from 'lodash/first'
import map from 'lodash/map'
import shuffle from 'lodash/shuffle'
import type { FunctionComponent } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { CountUp } from 'use-count-up'

export const BUBBLES_COUNTER_DEFAULT_DURATION = 1.5

export const useAnimateControls = ({
  onScreen,
  duration,
}: {
  onScreen: boolean
  duration: number
}) => {
  const controls = useAnimation()

  const [animationPlayed, setAnimationPlayed] = useState(false)

  useEffect(() => {
    if (onScreen && !animationPlayed) {
      controls.start({
        scale: [1, 1.2, 1],
        transition: {
          delay: duration - 0.125,
          duration: 0.25,
        },
      })
      setAnimationPlayed(true)
    }
  }, [animationPlayed, controls, duration, onScreen])

  return controls
}

const StyledBubble = styled(motion.div)<{
  $backgroundColor: string
  $index: number
  $length: number
}>(({ $backgroundColor, $index, $length }) => {
  const first = $index === 0
  const uneven = $length % 2 === 1
  const $isLastAndUneven = uneven && $index + 1 === $length
  const isRightColumn = $index % 2 === 1
  return [
    isRightColumn ? tw`-ml-px-16 sm:(-ml-px-20)` : tw`sm:-ml-0`,
    `background-color: ${$backgroundColor};`,
    tw`p-px-16 flex flex-col justify-center items-center rounded-full 
    height[150px] width[150px] sm:(height[250px] width[250px]) 
    md:(p-px-24 height[200px] width[200px]) ml:(px-px-20 height[300px] width[300px]) 
    ml:(p-px-48)
    lg:(px-px-40 height[400px] width[400px])`,
    !first &&
      tw`-mt-px-16 md:(-ml-px-20 mt-0) ml:(-ml-px-40 mt-0) lg:(-ml-px-40 mt-0)`,
    $index === 1 && tw`mt-0`,
    $isLastAndUneven &&
      tw`translate-x-1/2 -ml-px-8 -mt-px-24 sm:(-mt-px-40 -ml-px-8) md:(translate-x-0 mt-0 -ml-px-20)`,
  ]
})

const StyledBigFont = styled(motion.p)<{ $teal?: boolean }>(({ $teal }) => [
  $teal ? tw`text-accentroAqua-full` : tw`text-accentroWhite-full`,
  tw`text-px-32 sm:(text-px-48) font-variant-numeric[tabular-nums] mt-px-12 md:(text-px-40) ml:(font-size[60px] mt-px-16) lg:font-size[90px] leading-none font-semibold`,
])
const StyledSmallFont = styled.p<{ $fullGray?: boolean }>(({ $fullGray }) => [
  $fullGray ? tw`text-accentroGray-full` : tw`text-accentroWhite-full`,
  tw`min-height[50px] text-px-13 leading-px-18 sm:(text-px-16 leading-px-24) md:(text-px-13 leading-px-18)
   ml:(text-px-16 leading-px-24) lg:(text-px-24 leading-px-36 min-height[72px]) text-center mt-px-8`,
])

const getIntOrFloatRegexForLocale = (local: LocaleString) => {
  const regex =
    local === 'de' ? /([0-9]+\,[0-9]+)|([0-9]+)/g : /([0-9]+\.[0-9]+)|([0-9]+)/g
  return regex
}

const getSurroundingString = (regex: RegExp, text?: string) => {
  return text?.replace(regex, '$')
}

const getCountFromString = (regex: RegExp, text?: string) => {
  const matches = text?.match(regex)
  return first(matches)
}

export const parseHeadingString = (locale: LocaleString, text?: string) => {
  const regex = getIntOrFloatRegexForLocale(locale)
  const surroundingString = getSurroundingString(regex, text)
  const countRegexResult = getCountFromString(regex, text)
  const count = countRegexResult
    ? parseFloat(countRegexResult?.replace(',', '.'))
    : null
  return {
    surroundingString,
    count,
  }
}

export const bubbleConfigs = [
  {
    bigFontTeal: true,
    smallFontGray: true,
    backgroundColor: 'rgb(237, 241, 242)',
  },
  {
    bigFontTeal: true,
    smallFontGray: true,
    backgroundColor: 'rgb(211, 223, 227)',
  },
  {
    bigFontTeal: false,
    smallFontGray: false,
    backgroundColor: 'rgb(80, 128, 144)',
  },
  {
    bigFontTeal: false,
    smallFontGray: false,
    backgroundColor: 'rgba(43, 45, 54, 1.0)',
  },
]

const Bubble: FunctionComponent<
  React.PropsWithChildren<{
    bigFontTeal?: boolean
    smallFontGray?: boolean
    count?: number | null
    subheading: string
    backgroundColor: string
    surroundingString?: string | null
    index: number
    onScreen: boolean
    length: number
    animationDuration: number
  }>
> = ({
  bigFontTeal,
  smallFontGray,
  count,
  subheading,
  backgroundColor,
  index,
  surroundingString,
  onScreen,
  length,
  animationDuration = BUBBLES_COUNTER_DEFAULT_DURATION,
}) => {
  const decimalFormat = useFormatToSoftDecimal({
    maxFractionDigits: 1,
    minFractionDigits: 1,
  })
  const cutoffFormat = useFormatToCurrencyCutoff()
  const format =
    typeof count === 'number' && count % 1 === 0 ? cutoffFormat : decimalFormat

  // Keep seperator while splitting, so we can determine pre and post based on array position of the seperator.
  const splitted = surroundingString?.split(/(\$)/g)
  const indexOfCount = splitted?.indexOf('$')
  const preText = indexOfCount ? splitted?.[indexOfCount - 1] : null
  const postText = indexOfCount ? splitted?.[indexOfCount + 1] : null

  const duration = animationDuration // random(1.2, 1.8, true)

  const controls = useAnimateControls({ duration, onScreen })

  return (
    <StyledBubble
      $length={length}
      $index={index}
      $backgroundColor={backgroundColor}
    >
      <StyledBigFont $teal={bigFontTeal} animate={controls}>
        {preText}
        {!!count && !isNaN(count) && (
          <CountUp
            duration={duration}
            end={count}
            isCounting={onScreen}
            formatter={format}
          />
        )}
        {postText}
      </StyledBigFont>
      <StyledSmallFont
        dangerouslySetInnerHTML={{ __html: subheading }}
        $fullGray={smallFontGray}
      />
    </StyledBubble>
  )
}

export const BubblesRibbon: FunctionComponent<
  React.PropsWithChildren<{
    block: FullBlockRibbonFragment
  }>
> = ({ block }) => {
  const declaredBubbles = useMemo(() => {
    return filter(
      [
        { heading: block.heading1, subheading: block.subheading1 },
        { heading: block.heading2, subheading: block.subheading2 },
        { heading: block.heading3, subheading: block.subheading3 },
        { heading: block.heading4, subheading: block.subheading4 },
      ],
      (bubble) => !!bubble.heading
    )
  }, [
    block.heading1,
    block.heading2,
    block.heading3,
    block.heading4,
    block.subheading1,
    block.subheading2,
    block.subheading3,
    block.subheading4,
  ])

  const locale = useLocale()
  const ref = useRef<HTMLDivElement | null>(null)
  const onScreen = useOnScreen(ref)
  const initialDuration = 1.2
  const durationPresets = shuffle(
    map(declaredBubbles, (_, index) => {
      return initialDuration + index * 0.2
    })
  )

  return (
    <div tw="flex justify-center" ref={ref}>
      <div tw="grid grid-cols-2 gap-0 sm:( width[500px]) md:(flex flex-row w-auto) items-center justify-center">
        {map(declaredBubbles, (bubble, index) => {
          const drawDuration = durationPresets[index]
          const config = {
            ...bubbleConfigs[index],
            ...parseHeadingString(locale, bubble.heading ?? undefined),
          }

          return (
            <Bubble
              onScreen={onScreen}
              key={index}
              {...config}
              subheading={bubble.subheading ?? ''}
              index={index}
              length={declaredBubbles.length}
              animationDuration={drawDuration}
            />
          )
        })}
      </div>
    </div>
  )
}
