import { FullGridWidthDiv } from '@dreamstack/feature-components'
import type { FullBlockRibbonFragment } from '@dreamstack/investors-graphql'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'
import { FramerTranlateLeftAnimation } from './FramerTranlateLeftAnimation'
import { TextRibbonContainer } from './TextRibbonContainer'

const PageWidthContainer = styled.div(() => [
  tw`flex`,
  `
  position: relative;
  overflow-x: hidden;
     `,
])

export const TextRibbon: FunctionComponent<React.PropsWithChildren<{
  block: FullBlockRibbonFragment
}>> = ({ block }) => {
  return (
    <FullGridWidthDiv>
      <PageWidthContainer>
        <FramerTranlateLeftAnimation>
          <TextRibbonContainer block={block} />
        </FramerTranlateLeftAnimation>

        <FramerTranlateLeftAnimation>
          <TextRibbonContainer block={block} />
        </FramerTranlateLeftAnimation>
      </PageWidthContainer>
    </FullGridWidthDiv>
  )
}
