import type { FullBlockRibbonFragment } from '@dreamstack/investors-graphql'
import type { FunctionComponent } from 'react'
import { BubblesRibbon } from './BubblesRibbon'
import { BubblesSmallFontRibbon } from './BubblesSmallFontRibbon'
import { RevealRibbon } from './RevealRibbon'
import { TextRibbon } from './TextRibbon'

// Attention: Keys have to match variant values from the contentful data model.
const ContentfulVariantNameMapping: Record<
  string,
  FunctionComponent<React.PropsWithChildren<{ block: FullBlockRibbonFragment }>>
> = {
  Reveal: RevealRibbon,
  SingleLine: TextRibbon,
  Bubbles: BubblesRibbon,
  BubblesSmallFont: BubblesSmallFontRibbon,
}

export const BlockRibbon: FunctionComponent<React.PropsWithChildren<{
  block: FullBlockRibbonFragment
}>> = ({ block }) => {
  if (!block.variant) {
    return null
  }
  const Ribbon = ContentfulVariantNameMapping[block.variant]
  return <Ribbon block={block} />
}
