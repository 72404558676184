import {
  useFormatToCurrencyCutoff,
  useFormatToSoftDecimal,
  useLocale,
} from '@dreamstack/i18n'
import type { FullBlockRibbonFragment } from '@dreamstack/investors-graphql'
import { useOnScreen } from '@dreamstack/util'
import { motion } from 'framer-motion'
import filter from 'lodash/filter'
import map from 'lodash/map'
import shuffle from 'lodash/shuffle'
import type { FunctionComponent } from 'react'
import { useMemo, useRef } from 'react'
import tw, { styled } from 'twin.macro'
import { CountUp } from 'use-count-up'
import {
  BUBBLES_COUNTER_DEFAULT_DURATION,
  bubbleConfigs,
  parseHeadingString,
  useAnimateControls,
} from './BubblesRibbon'

const StyledBubble = styled(motion.div)<{
  $backgroundColor: string
}>(({ $backgroundColor }) => [
  `background-color: ${$backgroundColor};`,
  tw`flex 
     height[150px] width[150px] sm:(height[300px] width[300px]) md:(height[200px] width[200px]) ml:(height[300px] width[300px])
     rounded-full
     -m-px-12 sm:(-m-px-16) md:(-m-px-12) ml:(-m-px-16) 
     text-center
     items-center
     justify-center
  `,
])

const StyledBigFont = styled(motion.p)<{ $darkBackground?: boolean }>(
  ({ $darkBackground }) => [
    $darkBackground ? tw`text-accentroWhite-full` : tw`text-accentroAqua-full`,
    tw`flex
       flex-col
       text-px-16 sm:(text-px-28) md:(text-px-20) ml:(text-px-28) 
       font-semibold
       tracking-tight
    `,
  ]
)
const StyledSmallFont = styled.p<{ $darkBackground?: boolean }>(
  ({ $darkBackground }) => [
    $darkBackground ? tw`text-accentroWhite-full` : null,
    tw`text-px-13 sm:(text-px-16) md:(text-px-13) ml:(text-px-16)`,
  ]
)

//
const Bubble: FunctionComponent<
  React.PropsWithChildren<{
    bigFontTeal?: boolean
    smallFontGray?: boolean
    count?: number | null
    subheading?: string
    backgroundColor: string
    surroundingString?: string
    onScreen: boolean
    animationDuration: number
  }>
> = ({
  bigFontTeal,
  smallFontGray,
  count,
  subheading,
  backgroundColor,
  surroundingString,
  onScreen,
  animationDuration = BUBBLES_COUNTER_DEFAULT_DURATION,
}) => {
  const decimalFormat = useFormatToSoftDecimal({
    maxFractionDigits: 1,
    minFractionDigits: 1,
  })
  const cutoffFormat = useFormatToCurrencyCutoff()
  const format =
    typeof count === 'number' && count % 1 === 0 ? cutoffFormat : decimalFormat

  // Keep separator while splitting, so we can determine pre and post based on array position of the seperator.
  const splitted = surroundingString?.split(/(\$)/g)
  const indexOfCount = splitted?.indexOf('$')
  const preText = indexOfCount ? splitted?.[indexOfCount - 1] : null
  const postText = indexOfCount ? splitted?.[indexOfCount + 1] : null

  const duration = animationDuration // random(1.2, 1.8, true)

  const controls = useAnimateControls({ duration, onScreen })

  return (
    <StyledBubble $backgroundColor={backgroundColor}>
      <div>
        <StyledBigFont animate={controls} $darkBackground={!bigFontTeal}>
          {preText}
          {!!count && !isNaN(count) && (
            <CountUp
              duration={duration}
              end={count}
              isCounting={onScreen}
              formatter={format}
            />
          )}
          {postText}
        </StyledBigFont>
        <StyledSmallFont
          $darkBackground={!smallFontGray}
          dangerouslySetInnerHTML={
            subheading ? { __html: subheading } : undefined
          }
        />
      </div>
    </StyledBubble>
  )
}

//TODO: FIXME: This component looks like copy pasta from Bubbles Ribbon. Merge both files.
export const BubblesSmallFontRibbon: FunctionComponent<
  React.PropsWithChildren<{
    block: FullBlockRibbonFragment
  }>
> = ({ block }) => {
  const declaredBubbles = useMemo(() => {
    return filter(
      [
        { heading: block.heading1, subheading: block.subheading1 },
        { heading: block.heading2, subheading: block.subheading2 },
        { heading: block.heading3, subheading: block.subheading3 },
        { heading: block.heading4, subheading: block.subheading4 },
      ],
      (bubble) => !!bubble.heading
    )
  }, [
    block.heading1,
    block.heading2,
    block.heading3,
    block.heading4,
    block.subheading1,
    block.subheading2,
    block.subheading3,
    block.subheading4,
  ])

  const locale = useLocale()
  const ref = useRef<HTMLDivElement | null>(null)
  const onScreen = useOnScreen(ref)
  const initialDuration = 1.2
  const durationPresets = shuffle(
    map(declaredBubbles, (_, index) => {
      return initialDuration + index * 0.2
    })
  )

  return (
    <div tw="flex justify-center">
      <div tw="grid grid-cols-2 md:(grid-cols-4) m-auto" ref={ref}>
        {map(declaredBubbles, (bubble, index) => {
          const drawDuration = durationPresets[index]
          const config = {
            ...bubbleConfigs[index],
            ...parseHeadingString(locale, bubble.heading ?? undefined),
          }

          return (
            <Bubble
              onScreen={onScreen}
              key={index}
              {...config}
              subheading={bubble.subheading ?? undefined}
              animationDuration={drawDuration}
            />
          )
        })}
      </div>
    </div>
  )
}
