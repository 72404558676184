import { Text_16_24 } from '@dreamstack/feature-components'
import type { FullBlockRibbonFragment } from '@dreamstack/investors-graphql'
import { motion } from 'framer-motion'
import type { FunctionComponent } from 'react'
import tw, { css, styled } from 'twin.macro'

const RibbonContainer = styled(motion.div)(() => [
  tw`flex whitespace-nowrap justify-between my-px-20 md:(my-px-40)`,
])

const Heading = styled.h1(() => [
  tw`font-semibold font-size[96px] line-height[90px] md:(font-size[144px] line-height[130px]) lg:(font-size[220px] line-height[200px])`,
  `letter-spacing: -3.44px;`,
])

const Separator = styled.div(() => [
  tw`flex flex-1 mx-px-48 w-px-64 md:(w-px-96) ml:(width[166px] mx-px-64)`,
  css`
    border-bottom: 4px solid black;
    height: 3px;
    align-self: center;
  `,
])

const SubHeading = styled(Text_16_24)(() => [
  tw`font-semibold pl-px-8 pt-px-12 md:(pl-px-12 pt-px-16) lg:(pl-px-20)`,
])

export const TextRibbonContainer: FunctionComponent<React.PropsWithChildren<{
  block: FullBlockRibbonFragment
}>> = ({ block }) => {
  return (
    <RibbonContainer layout="position">
      <Separator />
      <div>
        <Heading>{block.heading1}</Heading>
        <SubHeading>{block.subheading1}</SubHeading>
      </div>
      <Separator />
      <div>
        <Heading>{block.heading2}</Heading>
        <SubHeading>{block.subheading2}</SubHeading>
      </div>
      <Separator />
      <div>
        <Heading>{block.heading3}</Heading>
        <SubHeading>{block.subheading3}</SubHeading>
      </div>
    </RibbonContainer>
  )
}
