import { motion } from 'framer-motion'
import type { FunctionComponent, PropsWithChildren } from 'react'

export const FramerTranlateLeftAnimation: FunctionComponent<React.PropsWithChildren<PropsWithChildren<{}>>> = ({ children }) => {
  return (
    <motion.div
      initial={{ translateX: '0' }}
      animate={{ translateX: '-100%' }}
      transition={{
        duration: 10,
        ease: 'linear',
        repeatType: 'loop',
        repeat: Infinity,
      }}
    >
      {children}
    </motion.div>
  )
}
