import {
  useIsLgOrGreater,
  useIsMdOrGreater,
  useIsSmOrSmaller,
  useRelativePositionOnPage,
} from '@dreamstack/feature-components'
import type { FullBlockRibbonFragment } from '@dreamstack/investors-graphql'
import { motion, transform, useMotionValue } from 'framer-motion'
import type { FunctionComponent} from 'react';
import { useEffect, useRef } from 'react'
import tw from 'twin.macro'

const fallbackImageUrl =
  'https://images.unsplash.com/photo-1582407947304-fd86f028f716?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1459&q=80'

const StyledFatFont = tw.div`font-size[26px] leading-none uppercase font-semibold md:(font-size[80px]) lg:(font-size[140px] letter-spacing[-2.8px])`
const StyledSmallFont = tw.div`font-size[13px] mt-px-4 ml-px-4 leading-none font-semibold md:(font-size[20px] ml-px-8 mt-px-8) lg:(text-px-32 ml-px-12 mt-px-12)`
const StyledRibbonTextWrapper = tw.div`md:py-px-24 lg:py-px-32`
const StyledClippedImage = tw(motion.div)`absolute inset-0 bg-cover z-10`

export const RevealRibbon: FunctionComponent<React.PropsWithChildren<{
  block: FullBlockRibbonFragment
}>> = ({ block }) => {
  // TODO: Refactor -> combine redundant stuff where possible.
  const ribbonText1Ref = useRef<HTMLDivElement | null>(null)
  const ribbonText2Ref = useRef<HTMLDivElement | null>(null)
  const ribbonText3Ref = useRef<HTMLDivElement | null>(null)

  const { relativePosition: scroll1 } =
    useRelativePositionOnPage(ribbonText1Ref)
  const { relativePosition: scroll2 } =
    useRelativePositionOnPage(ribbonText2Ref)
  const { relativePosition: scroll3 } =
    useRelativePositionOnPage(ribbonText3Ref)

  const translateX1 = useMotionValue(0)
  const translateX2 = useMotionValue(0)
  const translateX3 = useMotionValue(0)

  const translateTextX1 = useMotionValue(0)
  const translateTextX2 = useMotionValue(0)
  const translateTextX3 = useMotionValue(0)

  const isMd = useIsMdOrGreater()
  const isLg = useIsLgOrGreater()
  const isSm = useIsSmOrSmaller()

  const extraPadding = isLg ? 80 : isMd ? 40 : isSm ? 12 : 80

  useEffect(() => {
    const newTranslateX1 = transform(
      scroll1,
      [0.8, 0.2],
      [
        0,
        (ribbonText1Ref?.current?.getBoundingClientRect()?.width ?? 0) +
          extraPadding,
      ]
    )
    const newTranslateTextX1 = transform(scroll1, [0.6, 0.2], [40, 0])
    const newTranslateX2 = transform(
      scroll2,
      [0.8, 0.2],
      [
        0,
        -(
          (ribbonText2Ref?.current?.getBoundingClientRect()?.width ?? 0) +
          extraPadding
        ),
      ]
    )
    const newTranslateTextX2 = transform(scroll2, [0.6, 0.2], [-40, 0])
    const newTranslateX3 = transform(
      scroll3,
      [0.8, 0.2],
      [
        0,
        (ribbonText3Ref?.current?.getBoundingClientRect()?.width ?? 0) +
          extraPadding,
      ]
    )
    const newTranslateTextX3 = transform(scroll3, [0.6, 0.2], [40, 0])
    translateX1.set(newTranslateX1)
    translateX2.set(newTranslateX2)
    translateX3.set(newTranslateX3)
    translateTextX1.set(newTranslateTextX1)
    translateTextX2.set(newTranslateTextX2)
    translateTextX3.set(newTranslateTextX3)
  }, [
    scroll1,
    scroll2,
    scroll3,
    extraPadding,
    translateTextX1,
    translateTextX2,
    translateTextX3,
    translateX1,
    translateX2,
    translateX3,
  ])

  const imgUrl = block.overlayImage?.url ?? fallbackImageUrl

  return (
    <motion.div tw="relative w-full overflow-hidden">
      <StyledClippedImage
        style={{
          backgroundImage: `url(${imgUrl})`,
          clipPath: `inset(0 0 66.666666% 0)`,
          translateX: translateX1,
        }}
      />
      <StyledClippedImage
        style={{
          backgroundImage: `url(${imgUrl})`,
          clipPath: `inset(33.333333% 0 33.333333% 0)`,
          translateX: translateX2,
        }}
      />
      <StyledClippedImage
        style={{
          backgroundImage: `url(${imgUrl})`,
          clipPath: `inset(66.6666666% 0 0 0)`,
          translateX: translateX3,
        }}
      />
      <div tw="">
        <div tw="flex">
          <StyledRibbonTextWrapper
            tw="md:pl-0 lg:pl-px-40"
            ref={ribbonText1Ref}
          >
            <motion.div
              style={{
                translateX: translateTextX1,
              }}
            >
              <StyledFatFont>{block.heading1}</StyledFatFont>
              <StyledSmallFont>{block.subheading1}</StyledSmallFont>
            </motion.div>
          </StyledRibbonTextWrapper>
        </div>
        <div tw="flex justify-end">
          <StyledRibbonTextWrapper
            tw="md:pr-px-40 lg:pr-px-274"
            ref={ribbonText2Ref}
          >
            <motion.div
              style={{
                translateX: translateTextX2,
              }}
            >
              <StyledFatFont>{block.heading2}</StyledFatFont>
              <StyledSmallFont>{block.subheading2}</StyledSmallFont>
            </motion.div>
          </StyledRibbonTextWrapper>
        </div>
        <div tw="flex">
          <StyledRibbonTextWrapper
            tw="md:pl-px-40 lg:pl-0"
            ref={ribbonText3Ref}
          >
            <motion.div
              style={{
                translateX: translateTextX3,
              }}
            >
              <StyledFatFont>{block.heading3}</StyledFatFont>
              <StyledSmallFont>{block.subheading3}</StyledSmallFont>
            </motion.div>
          </StyledRibbonTextWrapper>
        </div>
      </div>
    </motion.div>
  )
}
